/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import Opening from "assets/images/News/OpenDag.jpg"
import Rooster from "assets/images/News/Rooster.jpg"
import ImageOverlay from "components/ImageOverlay";

function Highlight() {
  return (
    <MKBox
      component="section"
      sx={{
        background: 'linear-gradient(to bottom,  #0092d2 0%,#0092d2 45%,#0c5ca3 100%)',
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
      color="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <MKTypography
        variant="h1"
        color="white"
        sx={({ breakpoints, typography: { size } }) => ({
          [breakpoints.down("md")]: {
            fontSize: size["3xl"],
          },
        })}
      >
        Uitgelicht
      </MKTypography>
      <Container>
        <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          <Grid item xs={12} lg={3}>
            <ImageOverlay image={Opening} size="250em" alt="Flyer Opening" title="Flyer van de opening nieuwe locatie" />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MKTypography
              variant="h3"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Opening nieuwe locatie
            </MKTypography>
            <p>
              Budokai Wor & Sportschool Tromp krijgt compleet nieuw onderkomen.<br />
              Op zaterdag 12 oktober is de grote opening!! <br />
              En iedereen is van harte welkom om in ons mooie trainingscentrum te komen kijken.<br />
              <a href="https://www.facebook.com/WingChunKungFuAssociatieSliedrecht" target="_blank">
                <MKTypography variant="body1" color="dark">Associatie Wing-Chun Kung-Fu Sliedrecht/Dordrecht</MKTypography>
              </a>
              <a href="https://www.facebook.com/BudokaiWor" target="_blank">
                <MKTypography variant="body1" color="dark">Budokai wor</MKTypography>
              </a>
              <a href="https://www.facebook.com/sportschooltrompalblasserwaard" target="_blank">
                <MKTypography variant="body1" color="dark">Sportschool Tromp</MKTypography>
              </a>
              <a href="https://www.facebook.com/profile.php?id=100063455525823" target="_blank">
                <MKTypography variant="body1" color="dark">Budo Ryu Ikigai </MKTypography>
              </a>
              Geven van alle lessen die er gegeven gaan worden een super toffe demonstratie. Ben je jong of oud en altijd al vechtsport willen doen in een gezellige en gemoedelijke omgeving? <br />
              Wacht dan niet langer. <br />
              Wij verwelkomen u 12 oktober onder het genot van een hapje en een drankje<br />
              <a href="https://www.google.com/maps/place/Professor+van+Musschenbroekstraat+4,+3362+SW+Sliedrecht/@51.8206002,4.7790208,17z/data=!3m1!4b1!4m6!3m5!1s0x47c6820cee10419d:0xa2e5b4e5ffef0d14!8m2!3d51.8206002!4d4.7815957!16s%2Fg%2F11h044qnkr?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                <MKTypography variant="body1" color="dark">Professor van Musschenbroekstraat 4</MKTypography>
              </a> 
              </p>
          </Grid>
          <Grid item xs={12}>
            
          </Grid>
          <Grid item xs={12} lg={3}>
            <ImageOverlay image={Rooster} size="250em" alt="Rooster" title="Rooster" />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MKTypography
              variant="h3"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Nieuwe Rooster
            </MKTypography>
            
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Highlight;
